<template>
  <div>
    <Modal :options="{width: '50vw'}">
      <div class="title">{{ !usuario_tmp.id ? 'Agregar usuario' : 'Editar usuario' }}</div>
      <div class="body">
        <div class="row form-group">
          <div class="col-sm-12 text-right">
            <BpbCheckbox v-model="usuario_de_account" label="Usuario existente" texton="Usuario de account" textoff="Usuario nuevo" />
          </div>
        </div>
        <template v-if="usuario_de_account">
          <div class="row form-group">
            <label for="buscar" class="col-form-label col-sm-3">Buscar usuario</label>
            <div class="col-sm-9"><input v-model="buscar_usuario" type="text" name="buscar" id="buscar" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="lista_usuarios" class="col-form-label col-sm-3">Usuarios</label>
            <div class="col-sm-9">
              <select v-model="usuario_tmp.account_id" name="lista_usuarios" id="lista_usuarios" class="form-control">
                <option v-for="usuario in usuarios_filtrados" :value="usuario.id">{{ usuario.nombre }}</option>
              </select>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-3">Nombre</label>
            <div class="col-sm-9"><input v-model="usuario_tmp.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-3">Email</label>
            <div class="col-sm-9"><input v-model="usuario_tmp.email" type="text" name="" id="" class="form-control"></div>
          </div>
        </template>
        <div class="row form-group">
          <label for="estatus" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <BpbSwitch v-model="usuario_tmp.estatus" texton="Activo" textoff="Inactivo" />
          </div>
        </div>
      </div>
      <div class="footer text-right">
        <button class="btn btn-primary mr-2" @click="enviar_usuario">{{ usuario_tmp.id ? 'Actualizar' : 'Agregar'}}</button>
        <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import BpbCheckbox from '@/components/Form/Checkbox'
import BpbSwitch from '@/components/Form/Switch'

import apiOtros from '@/apps/fyc/api/otros';

export default {
  components: {
    Modal, BpbSwitch, BpbCheckbox
  },
  props: {
    usuario: {
      type: Object,
    }
  },
  data() {
    return {
      usuario_tmp: {
        nombre: null,
        account_id: null,
        email: null,
        estatus: true
      },
      usuario_de_account: false,
      account_usuarios: [],
      buscar_usuario: null
    }
  },
  mounted() {
    if (this.usuario)
      this.editar_usuario();
  },
  methods: {
    async obtener_usuarios() {
      try {
        this.account_usuarios = (await apiOtros.obtener_usuarios()).data;
        if (!this.usuario_tmp.account_id)
          this.usuario_tmp.account_id = this.account_usuarios[0].id;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    editar_usuario(val) {
      this.usuario_tmp = !val ? this.$helper.clone(this.usuario) : this.$helper.clone(val);

      if (this.usuario_tmp.account_id) {
          this.usuario_de_account = true;
          setTimeout(() => {
            this.usuario_tmp.account_id = this.usuario.account_id;
          },500)
      }

      this.usuario_tmp.estatus = this.usuario_tmp.estatus == 'activo';
    },
    enviar_usuario() {
      if (this.usuario_tmp.account_id) {
        this.account_usuarios.forEach(usuario => {
          if (this.usuario_tmp.account_id == usuario.id) {
            this.usuario_tmp.nombre = usuario.nombre;
            return;
          }
        });
      }

      this.usuario_tmp.estatus = this.usuario_tmp.estatus ? 'activo' : 'inactivo';

      this.$emit('update', this.usuario_tmp);
    }
  },
  computed: {
    usuarios_filtrados() {
      if (!this.buscar_usuario)
        return this.account_usuarios;

      let usuarios = [];

      this.account_usuarios.forEach((usuario) => {
        let nombre = usuario.nombre.toLowerCase();
        console.log('nombre', nombre);
        console.log('buscar_usuario', this.buscar_usuario);

        if (nombre.indexOf(this.buscar_usuario) != -1)
          usuarios.push(usuario);
      })

      return usuarios;
    }
  },
  watch: {
    usuario_de_account(val) {
      if (val) {
        if (this.account_usuarios.length == 0)
          this.obtener_usuarios();
        else if(!this.usuario_tmp.account_id)
          this.usuario_tmp.account_id = this.account_usuarios[0].id;
      }
    },
    usuario: {
      handler(val) {
        this.editar_usuario(val);
      },
      deep: true
    }
  }
}
</script>